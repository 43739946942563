import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { NoticeOfIntentTaxDeductionFormComponentModel, NoticeOfIntentTaxDeductionFormSubmissionModel } from 'src/app/model/notice-of-intent-tax-deduction.model';
import { Router } from '@angular/router';
import { noticeOfIntentTaxDeductionForm_Disclaimer, noticeOfIntentTaxDeductionForm_FinancialYearContributionsAmountId, noticeOfIntentTaxDeductionForm_FinancialYearDropdownOptions, noticeOfIntentTaxDeductionForm_Form, noticeOfIntentTaxDeductionForm_HasWithdrawalPending, noticeOfIntentTaxDeductionForm_SelectedFinancialYear, noticeOfIntentTaxDeductionForm_Submitted, noticeOfIntentTaxDeductionForm_TaxDeductionClaimTypes } from './selectors';
import { KeyValueModel } from '@ifaa-components/ui-components';
import { RequestAction, ResetFormStateAction, SetDisclaimerAction, SetFinancialYearDropdownOptionsAction, SetTaxDeductionRadioOptionsAction } from './actions';
import { SetValueAction } from 'ngrx-forms';
import { CurrencyPipe } from '@angular/common';
import { distinctUntilChanged } from 'rxjs';
import { ResetComponentStateAction } from '../notice-of-intent-tax-deduction-container/actions';
import { NoticeOfIntentTaxDeductionFormComponentForm } from './state';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoiPendingWithdrawalDialog } from './noi-pending-withdrawal-dialog/noi-pending-withdrawal-dialog.component';
import { commomState_ElevatedAccess, commonState_IsSending } from 'src/app/store/common/common.selectors';

@Component({
    selector: 'app-notice-of-intent-tax-deduction-form',
    templateUrl: './notice-of-intent-tax-deduction-form.component.html',
    styleUrls: ['./notice-of-intent-tax-deduction-form.component.scss']
})

export class NoticeOfIntentTaxDeductionFormComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set data(value: NoticeOfIntentTaxDeductionFormComponentModel) {
        this.model = value;
    }
    @Input() set account(value: number) {
        this.accountId = value;
    }

    form$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_Form));
    taxDeductionClaimTypes$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_TaxDeductionClaimTypes));
    financialYearDropdownOptions$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_FinancialYearDropdownOptions));
    selectedFinancialYear$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_SelectedFinancialYear));
    financialYearContributionsAmountId$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_FinancialYearContributionsAmountId));
    submitted$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_Submitted));
    hasWithdrawalPending$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_HasWithdrawalPending));
    elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));
    disclaimer$ = this.store.pipe(select(noticeOfIntentTaxDeductionForm_Disclaimer));

    isSubmitting$ = this.store.pipe(select(commonState_IsSending));

    tfnTooltipText: string = "It isn’t compulsory to provide your TFN, but if its not provided additional tax may be deducted from your superannuation benefit and the taxable component of any cash payment will be taxed at the highest marginal rate plus applicable levies if you are under age 60.";
    model: NoticeOfIntentTaxDeductionFormComponentModel = null;
    accountId: number = 0;
    currentFinancialYear: string = '';
    previousFinancialYear: string = '';
    noiPendingWithdrawalRef: MatDialogRef<NoiPendingWithdrawalDialog, any> = null;
    elevatedAccess = false;

    currencyPipe = new CurrencyPipe("en-au");

    constructor(public store: Store<AppState>,
        public dialog: MatDialog,
        private router: Router) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();

        this.sub = this.elevatedAccess$.subscribe(x => {
            this.elevatedAccess = x;
        })

        var taxDeductionClaimTypes = [] as KeyValueModel[];
        taxDeductionClaimTypes.push({ key: 0, value: "New" });
        taxDeductionClaimTypes.push({ key: 1, value: "Variation" });

        var financialYearOptions = [] as KeyValueModel[];
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1;

        // save the values so they can be used in submission to api
        if (currentMonth < 7) {
            this.currentFinancialYear = `FY ${currentYear - 1}/${currentYear}`;
            this.previousFinancialYear = `FY ${currentYear - 2}/${currentYear - 1}`;
        }
        else {
            this.currentFinancialYear = `FY ${currentYear}/${currentYear + 1}`;
            this.previousFinancialYear = `FY ${currentYear - 1}/${currentYear}`;
        }
        financialYearOptions.push({ key: 0, value: `Current financial year: ${this.currentFinancialYear}` });
        financialYearOptions.push({ key: 1, value: `Previous financial year: ${this.previousFinancialYear}` });

        this.dispatch(this.store, SetTaxDeductionRadioOptionsAction({ payload: taxDeductionClaimTypes }));
        this.dispatch(this.store, SetFinancialYearDropdownOptionsAction({ payload: financialYearOptions }))

        // Save the current/previous fy contributions into form state so are used in validations
        this.sub = this.financialYearContributionsAmountId$.subscribe(x => {
            if (x) {
                this.store.dispatch(new SetValueAction(x, this.model.contributions))
            }
        });

        this.sub = this.selectedFinancialYear$.pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))).subscribe(x => {
            if (x.financialYear === 0) {
                this.store.dispatch(new SetValueAction(x.controlId, this.currencyPipe.transform(this.model.contributions.currentYear)));
            }
            else {
                this.store.dispatch(new SetValueAction(x.controlId, this.currencyPipe.transform(this.model.contributions.previousYear)));
            }

            if (x.claimType === 1) {
                this.dispatch(this.store, SetDisclaimerAction({ disclaimer: this.model.formWording.variantTypeSubmissionDisclaimer }))
            }
            else {
                if (x.financialYear === 0)
                    this.dispatch(this.store, SetDisclaimerAction({ disclaimer: this.model.formWording.newTypeSubmissionDisclaimer.replace("{date}", this.currentFinancialYear) }));
                else
                    this.dispatch(this.store, SetDisclaimerAction({ disclaimer: this.model.formWording.newTypeSubmissionDisclaimer.replace("{date}", this.previousFinancialYear) }));
            }
        });

        this.sub = this.hasWithdrawalPending$.subscribe(x => {
            if (x) {
                this.noiPendingWithdrawalRef = this.dialog.open(NoiPendingWithdrawalDialog, {
                    width: '700px',
                })
            }
        })
        this.noiPendingWithdrawalRef?.afterClosed().subscribe(() => {
            this.noiPendingWithdrawalRef = null;
        })
    }

    ngOnDestroy() {
        this.resetState();
        super.ngOnDestroyBase();
    }

    submit(data: NoticeOfIntentTaxDeductionFormComponentForm) {
        if (this.elevatedAccess) return;
        var submission = {
            financialYear: data.financialYear === 0 ? this.currentFinancialYear : this.previousFinancialYear,
            originalClaimAmount: data.claimType === 0 ? null : data.originalClaimAmount,
            claimAmount: data.claimAmount,
            hasWithdrawalPending: data.hasWithdrawalPending
        } as NoticeOfIntentTaxDeductionFormSubmissionModel;

        this.dispatch(this.store, RequestAction({ accountId: this.accountId, payload: submission }))
    }

    cancel() {
        this.resetState();
    }

    updatePersonalDetails() {
        this.resetState();
        this.router.navigate(['/personal-edit'])
    }

    resetState() {
        this.dispatch(this.store, ResetFormStateAction());
        this.dispatch(this.store, ResetComponentStateAction());
    }

    goHome() {
        this.store.dispatch(ResetComponentStateAction());
    }
}

